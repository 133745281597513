@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
#__next::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

/**
 * Mainlayout의  drawer 문제로 오버 스크롤이 생겨 수정합니다.
 */
.drawer-toggle ~ .drawer-content {
    overflow: hidden;
}

@layer base {
    body {
        background: #242933;
        -webkit-tap-highlight-color: transparent; /* for removing the highlight */
        outline: none;
        touch-action: pan-x pan-y; /* pinchZoom 제한 */
    }

    button {
        touch-action: manipulation; /* 버튼의 더블탭 확대 방지 */
    }

    .scrollbar-hide {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }
}

@layer utilities {
    .triangle {
        clip-path: polygon(100% 50%, 0 0, 0 100%);
    }

    @keyframes slide-in-bottom {
        0% {
            transform: translateY(2000px);
            opacity: 0;
        }
        100% {
            transform: translateY(calc(70vh));
            opacity: 1;
        }
    }
    .animate-alert-in {
        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-out-bottom {
        0% {
            transform: translateY(calc(70vh));
            opacity: 1;
        }
        100% {
            transform: translateY(2000px);
            opacity: 0;
        }
    }
    .animate-alert-out {
        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }
}


/* react date picker */
.react-datepicker-popper {
    z-index: 20!important;
}
